/* eslint-disable import/no-anonymous-default-export */
export default [
  {
    id: 1,
    img: '/book-images/1.webp',
    Page: 0,
  },
  {
    id: 2,
    img: '/book-images/2.webp',
    Page: 1,
  },
  {
    id: 3,
    img: '/book-images/3.webp',
    Page: 2,
  },
  {
    id: 4,
    img: '/book-images/4.webp',
    Page: 3,
  },
  {
    id: 5,
    img: '/book-images/5.webp',
    Page: 4,
  },
  {
    id: 6,
    img: '/book-images/6.webp',
    Page: 5,
  },
  {
    id: 7,
    img: '/book-images/7.webp',
    Page: 6,
  },
  {
    id: 8,
    img: '/book-images/8.webp',
    Page: 7,
  },
  {
    id: 9,
    img: '/book-images/9.webp',
    Page: 8,
  },
  {
    id: 10,
    img: '/book-images/10.webp',
    Page: 9,
  },
  {
    id: 11,
    img: '/book-images/11.webp',
    Page: 10,
  },
  {
    id: 12,
    img: '/book-images/12.webp',
    Page: 11,
  },
  {
    id: 13,
    img: '/book-images/13.webp',
    Page: 12,
  },
  {
    id: 14,
    img: '/book-images/14.webp',
    Page: 13,
  },
  {
    id: 15,
    img: '/book-images/15.webp',
    Page: 14,
  },
  {
    id: 16,
    img: '/book-images/16.webp',
    Page: 15,
  },
  {
    id: 17,
    img: '/book-images/17.webp',
    Page: 16,
  },
  {
    id: 18,
    img: '/book-images/18.webp',
    Page: 17,
  },
  {
    id: 19,
    img: '/book-images/19.webp',
    Page: 18,
  },
  {
    id: 20,
    img: '/book-images/20.webp',
    Page: 19,
  },
  {
    id: 21,
    img: '/book-images/21.webp',
    Page: 20,
  },
  {
    id: 22,
    img: '/book-images/22.webp',
    Page: 21,
  },
  {
    id: 23,
    img: '/book-images/23.webp',
    Page: 22,
  },
  {
    id: 24,
    img: '/book-images/24.webp',
    Page: 23,
  },
  {
    id: 25,
    img: '/book-images/25.webp',
    Page: 24,
  },
  {
    id: 26,
    img: '/book-images/26.webp',
    Page: 25,
  },
  {
    id: 27,
    img: '/book-images/27.webp',
    Page: 26,
  },
  {
    id: 28,
    img: '/book-images/28.webp',
    Page: 27,
  },
  {
    id: 29,
    img: '/book-images/29.webp',
    Page: 28,
  },
  {
    id: 30,
    img: '/book-images/30.webp',
    Page: 29,
  },
  {
    id: 31,
    img: '/book-images/31.webp',
    Page: 30,
  },
  {
    id: 32,
    img: '/book-images/32.webp',
    Page: 31,
  },
  {
    id: 33,
    img: '/book-images/33.webp',
    Page: 32,
  },
  {
    id: 34,
    img: '/book-images/34.webp',
    Page: 33,
  },
  {
    id: 35,
    img: '/book-images/35.webp',
    Page: 34,
  },
  {
    id: 36,
    img: '/book-images/36.webp',
    Page: 35,
  },
  {
    id: 37,
    img: '/book-images/37.webp',
    Page: 36,
  },
  {
    id: 38,
    img: '/book-images/38.webp',
    Page: 37,
  },
  {
    id: 39,
    img: '/book-images/39.webp',
    Page: 38,
  },
  {
    id: 40,
    img: '/book-images/40.webp',
    Page: 39,
  },
  {
    id: 41,
    img: '/book-images/41.webp',
    Page: 40,
  },
  {
    id: 42,
    img: '/book-images/42.webp',
    Page: 41,
  },
  {
    id: 43,
    img: '/book-images/43.webp',
    Page: 42,
  },
  {
    id: 44,
    img: '/book-images/44.webp',
    Page: 43,
  },
  {
    id: 45,
    img: '/book-images/45.webp',
    Page: 44,
  },
  {
    id: 46,
    img: '/book-images/46.webp',
    Page: 45,
  },
  {
    id: 47,
    img: '/book-images/47.webp',
    Page: 46,
  },
  {
    id: 48,
    img: '/book-images/48.webp',
    Page: 47,
  },
  {
    id: 49,
    img: '/book-images/49.webp',
    Page: 48,
  },
  {
    id: 50,
    img: '/book-images/50.webp',
    Page: 49,
  },
  {
    id: 51,
    img: '/book-images/51.webp',
    Page: 50,
  },
  {
    id: 52,
    img: '/book-images/52.webp',
    Page: 51,
  },
  {
    id: 53,
    img: '/book-images/53.webp',
    Page: 52,
  },
  {
    id: 54,
    img: '/book-images/54.webp',
    Page: 53,
  },
  {
    id: 55,
    img: '/book-images/55.webp',
    Page: 54,
  },
  {
    id: 56,
    img: '/book-images/56.webp',
    Page: 55,
  },
  {
    id: 57,
    img: '/book-images/57.webp',
    Page: 56,
  },
  {
    id: 58,
    img: '/book-images/58.webp',
    Page: 57,
  },
  {
    id: 59,
    img: '/book-images/59.webp',
    Page: 58,
  },
  {
    id: 60,
    img: '/book-images/60.webp',
    Page: 59,
  },
  {
    id: 61,
    img: '/book-images/61.webp',
    Page: 60,
  },
  {
    id: 62,
    img: '/book-images/62.webp',
    Page: 61,
  },
  {
    id: 63,
    img: '/book-images/63.webp',
    Page: 62,
  },
  {
    id: 64,
    img: '/book-images/64.webp',
    Page: 63,
  },
  {
    id: 65,
    img: '/book-images/65.webp',
    Page: 64,
  },
  {
    id: 66,
    img: '/book-images/66.webp',
    Page: 65,
  },
  {
    id: 67,
    img: '/book-images/67.webp',
    Page: 66,
  },
  {
    id: 68,
    img: '/book-images/68.webp',
    Page: 67,
  },
  {
    id: 69,
    img: '/book-images/69.webp',
    Page: 68,
  },
  {
    id: 70,
    img: '/book-images/70.webp',
    Page: 69,
  },
];
