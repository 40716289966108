import React, { useEffect, useRef, useState } from 'react';
import {
  BsChevronLeft,
  BsChevronRight,
  BsFillXCircleFill,
} from 'react-icons/bs';
import Modal from 'react-modal';
import HTMLFlipBook from 'react-pageflip';
import { Document, Page as ReactPdfPage, pdfjs } from 'react-pdf';

import Page from './components/Page';

import Header from './components/Header';
import Loading from './components/Loading';
import PreLoader from './components/PreLoader';
import pageData from './data/PageData.js';
import bookPDF from './data/cook-book.pdf';

pdfjs.GlobalWorkerOptions.workerSrc = `//cdnjs.cloudflare.com/ajax/libs/pdf.js/${pdfjs.version}/pdf.worker.js`;
Modal.setAppElement('#root');

const { innerHeight, innerWidth } = window;

const height =
  innerWidth > 1919
    ? Math.round(innerHeight * 0.8)
    : innerWidth < 768
    ? Math.round(innerHeight * 0.6)
    : Math.round(innerHeight * 0.9);
const width = Math.round(height * 0.7075471698113208);

function App() {
  const flipBook = useRef();

  const [isVideoOpen, setIsVideoOpen] = useState(false);
  const [videoUrl, setVideoUrl] = useState('');
  const [isZoomOpen, setIsZoomOpen] = useState(false);
  const [zoomPage, setZoomPage] = useState(1);
  const [isLoading, setIsLoading] = useState(true);

  const [zoomLevel, setZoomLevel] = useState(100);

  const [currentPage, setCurrentPage] = useState(1);

  const closeModal = () => {
    setIsVideoOpen(false);
    setIsZoomOpen(false);
    setVideoUrl('');
  };

  const pages = [];
  pageData.map((data) => {
    pages.push(
      <Page
        key={data.pageNumber}
        width={width}
        height={height}
        pageNumber={data.pageNumber}
        video={data.video}
        setIsVideoOpen={setIsVideoOpen}
        setVideoUrl={setVideoUrl}
      />
    );
  });

  const playAudio = (e) => {
    const audio = new Audio('/page-flip.mp3');
    if (e.data === 'flipping') audio.play();
  };

  useEffect(() => {
    // Simulate a delay to mimic loading
    setTimeout(() => {
      setIsLoading(false); // Set loading to false when content is ready
    }, 2000); // Adjust the delay as needed
  }, []);

  const handleZoomChange = (event) => {
    setZoomLevel(event.target.value);
  };

  // useEffect(() => {
  //   const mobileWidthThreshold = 992;
  //
  //   if (innerWidth < mobileWidthThreshold) {
  //     setZoomLevel(70);
  //   } else {
  //     setZoomLevel(100);
  //   }
  // }, []);

  const [showZoomIcon, setShowZoomIcon] = useState(false);

  useEffect(() => {
    // Delay showing the zoom icon for 5 seconds (5000 milliseconds)
    const delay = 5000;

    const timer = setTimeout(() => {
      setShowZoomIcon(true);
    }, delay);

    // Clean up the timer when the component unmounts
    return () => clearTimeout(timer);
  }, []);

  const toggleZoomModal = () => {
    setIsZoomOpen(!isZoomOpen); // Toggle the value of isZoomOpen
  };

  const zoomModalRef = useRef(null);

  const [dragging, setDragging] = useState(false);
  const [position, setPosition] = useState({ x: 0, y: 0 });

  useEffect(() => {
    const handleMouseDown = (e) => {
      if (isZoomOpen) {
        setDragging(true);
        setPosition({
          x: e.clientX,
          y: e.clientY,
        });
      }
    };

    const handleMouseUp = () => {
      setDragging(false);
    };

    const handleMouseMove = (e) => {
      if (dragging && zoomModalRef.current) {
        const modalContainer = zoomModalRef.current;
        const newX = position.x - e.clientX;
        const newY = position.y - e.clientY;

        // Update the scroll position of the zoom modal container
        modalContainer.scrollLeft += newX;
        modalContainer.scrollTop += newY;

        setPosition({ x: e.clientX, y: e.clientY });
      }
    };

    window.addEventListener('mousedown', handleMouseDown);
    window.addEventListener('mouseup', handleMouseUp);
    window.addEventListener('mousemove', handleMouseMove);

    return () => {
      window.removeEventListener('mousedown', handleMouseDown);
      window.removeEventListener('mouseup', handleMouseUp);
      window.removeEventListener('mousemove', handleMouseMove);
    };
  }, [dragging, isZoomOpen, position]);

  // Handle zoom change when scrolling with the mouse wheel
  const handleWheelScroll = (e) => {
    if (isZoomOpen) {
      // Calculate the new zoom level based on the mouse wheel delta
      const delta = e.deltaY;
      const newZoomLevel = zoomLevel + (delta > 0 ? -5 : 5); // Adjust the step as needed

      // Ensure the zoom level stays within the desired range (min and max)
      const minZoom = 70;
      const maxZoom = 200;
      const clampedZoomLevel = Math.min(
        maxZoom,
        Math.max(minZoom, newZoomLevel)
      );

      // Update the zoom level state
      setZoomLevel(clampedZoomLevel);
    }
  };

  return (
    <>
      {isLoading ? ( // Conditional rendering based on the loading state
        <div className='w-screen h-screen flex justify-center items-center main-loading'>
          {/*<Loading />*/}
          <PreLoader />
        </div>
      ) : (
        <div className='main-content w-screen h-screen'>
          <div className='background-images'>
            <div className='bg-1'></div>
            <div className='bg-2'></div>
          </div>

          {/*Header*/}
          <Header
            toggleZoomModal={toggleZoomModal}
            flipBook={flipBook}
            currentPage={currentPage}
            isZoomOpen={isZoomOpen}
          />
          {/*Header*/}

          {isZoomOpen && (
            <div className='zoom-slider text-center' isOpen={isZoomOpen}>
              <input
                type='range'
                min='70'
                max='200'
                value={zoomLevel}
                onChange={handleZoomChange}
              />
              <p className='zoom-text'>Adjust Zoom Level</p>
            </div>
          )}

          <div className='main-content-inner flex flex-row justify-center items-center h-full'>
            <div className='absolute sm:relative slide-btn slide-btn-left'>
              <button
                onClick={() => {
                  flipBook.current.pageFlip().flipPrev();
                  closeModal();
                }}
              >
                <BsChevronLeft className='w-8 lg:w-12 h-8 lg:h-12 text-white' />
              </button>
            </div>
            <div className='w-full book-content relative'>
              {/*{showZoomIcon && (*/}
              {/*<div className='zoom-btn'>*/}
              {/*  <BsSearch className='zoom-icon'*/}
              {/*            onClick={() => {*/}
              {/*              setIsZoomOpen(true)*/}
              {/*            }}/>*/}
              {/*</div>*/}
              {/*)}*/}

              <Document file={bookPDF} loading={<Loading />}>
                <HTMLFlipBook
                  ref={flipBook}
                  width={width}
                  height={height}
                  maxShadowOpacity={0.5}
                  showCover={true}
                  mobileScrollSupport={true}
                  clickEventForward={false}
                  useMouseEvents={false}
                  onChangeState={(e) => playAudio(e)}
                  onFlip={(e) => {
                    setCurrentPage(e.data + 1);
                    setZoomPage(e.data + 1);
                  }}
                >
                  {pages}
                </HTMLFlipBook>

                {/*Zoom Modal*/}
                {isZoomOpen && (
                  <div
                    className={`zoom-modal ${isZoomOpen ? 'open' : ''}`}
                    isOpen={isZoomOpen}
                    ref={zoomModalRef}
                    onWheel={handleWheelScroll}
                  >
                    <div
                      className='zoom-modal-container'
                      style={{ transform: `scale(${zoomLevel / 100})` }}
                    >
                      <ReactPdfPage
                        pageNumber={zoomPage}
                        width={width}
                        height={height}
                        scale={2}
                        renderTextLayer={false}
                      />
                    </div>
                  </div>
                )}
              </Document>
            </div>
            <div className='absolute sm:relative slide-btn slide-btn-right'>
              <button
                onClick={() => {
                  flipBook.current.pageFlip().flipNext();
                  closeModal();
                }}
              >
                <BsChevronRight className='w-8 lg:w-12 h-8 lg:h-12 text-white' />
              </button>
            </div>
          </div>
        </div>
      )}

      {/*Video Modal*/}
      <Modal
        className='video-modal'
        isOpen={isVideoOpen}
        shouldCloseOnOverlayClick={true}
        onRequestClose={closeModal}
      >
        <div className='modal-content'>
          <div className='modal-header flex justify-end mb-2'>
            <button
              type='button'
              className='modal-close-btn'
              onClick={closeModal}
            >
              <BsFillXCircleFill className='w-5 sm:w-6 h-5 sm:h-6 text-gray-800' />
            </button>
          </div>
          <div className='modal-body'>
            <iframe
              width='100%'
              height='100%'
              src={videoUrl}
              title='YouTube video player'
              frameBorder='0'
              allow='accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share'
              allowFullScreen
            ></iframe>
          </div>
        </div>
      </Modal>
    </>
  );
}

export default App;
