/* eslint-disable import/no-anonymous-default-export */
export default [
  {
    id: 1,
    name: 'Ampara',
    Page: 8,
  },
  {
    id: 2,
    name: 'Embilipitiya',
    Page: 14,
  },
  {
    id: 3,
    name: 'Ratnapura',
    Page: 20,
  },
  {
    id: 4,
    name: 'Kandy',
    Page: 26,
  },
  {
    id: 5,
    name: 'Dambulla',
    Page: 32,
  },
  {
    id: 6,
    name: 'Tangalle',
    Page: 38,
  },
  {
    id: 7,
    name: 'Jaffna',
    Page: 44,
  },
  {
    id: 8,
    name: 'Negombo',
    Page: 50,
  },
  {
    id: 9,
    name: 'Batticaloa',
    Page: 56,
  },
  {
    id: 10,
    name: 'Nuwara Eliya',
    Page: 62,
  },
];
