const Preloader = () => {
  return (
    <div class='book'>
      <div class='inner'>
        <div class='left'></div>
        <div class='middle'></div>
        <div class='right'></div>
      </div>
      <ul>
        <li></li>
        <li></li>
        <li></li>
        <li></li>
        <li></li>
        <li></li>
        <li></li>
        <li></li>
        <li></li>
        <li></li>
        <li></li>
        <li></li>
        <li></li>
        <li></li>
        <li></li>
        <li></li>
        <li></li>
        <li></li>
      </ul>
    </div>
  );
};

export default Preloader;
