const pageData = [
  {
    pageNumber: 1,
    video: null,
  },
  {
    pageNumber: 2,
    video: null,
  },
  {
    pageNumber: 3,
    video: null,
  },
  {
    pageNumber: 4,
    video: null,
  },
  {
    pageNumber: 5,
    video: null,
  },
  {
    pageNumber: 6,
    video: null,
  },
  {
    pageNumber: 7,
    video: null,
  },
  {
    pageNumber: 8,
    video: null,
  },
  {
    pageNumber: 9,
    video: null,
  },
  {
    pageNumber: 10,
    video: {
      url: 'https://www.youtube.com/embed/cte9gqpoDy0',
      top: '10%',
      left: '50%',
      transform: 'translateX(-50%)',
    },
  },
  {
    pageNumber: 11,
    video: {
      url: 'https://www.youtube.com/embed/h09SwU1676c',
      top: '10%',
      left: '50%',
      transform: 'translateX(-50%)',
    },
  },
  {
    pageNumber: 12,
    video: {
      url: 'https://www.youtube.com/embed/-CSWcQTtI7s',
      top: '10%',
      left: '50%',
      transform: 'translateX(-50%)',
    },
  },
  {
    pageNumber: 13,
    video: {
      url: 'https://www.youtube.com/embed/qkZ88BOroOc',
      top: '10%',
      left: '50%',
      transform: 'translateX(-50%)',
    },
  },
  {
    pageNumber: 14,
    video: null,
  },
  {
    pageNumber: 15,
    video: null,
  },
  {
    pageNumber: 16,
    video: {
      url: 'https://www.youtube.com/embed/nJsKMz93nUk',
      top: '10%',
      left: '50%',
      transform: 'translateX(-50%)',
    },
  },
  {
    pageNumber: 17,
    video: {
      url: 'https://www.youtube.com/embed/t0ruT5VB3L0',
      top: '10%',
      left: '50%',
      transform: 'translateX(-50%)',
    },
  },
  {
    pageNumber: 18,
    video: {
      url: 'https://www.youtube.com/embed/WqR1xxPyt7o',
      top: '10%',
      left: '50%',
      transform: 'translateX(-50%)',
    },
  },
  {
    pageNumber: 19,
    video: {
      url: 'https://www.youtube.com/embed/fk0JaPYPLXw',
      top: '10%',
      left: '50%',
      transform: 'translateX(-50%)',
    },
  },
  {
    pageNumber: 20,
    video: null,
  },
  {
    pageNumber: 21,
    video: null,
  },
  {
    pageNumber: 22,
    video: {
      url: 'https://www.youtube.com/embed/AoUyGASbkCI',
      top: '10%',
      left: '50%',
      transform: 'translateX(-50%)',
    },
  },
  {
    pageNumber: 23,
    video: {
      url: 'https://www.youtube.com/embed/W8YhnYkfekk',
      top: '10%',
      left: '50%',
      transform: 'translateX(-50%)',
    },
  },
  {
    pageNumber: 24,
    video: {
      url: 'https://www.youtube.com/embed/kdbIHbZgQlg',
      top: '10%',
      left: '50%',
      transform: 'translateX(-50%)',
    },
  },
  {
    pageNumber: 25,
    video: {
      url: 'https://www.youtube.com/embed/5s8QCpGmWdY',
      top: '10%',
      left: '50%',
      transform: 'translateX(-50%)',
    },
  },
  {
    pageNumber: 26,
    video: null,
  },
  {
    pageNumber: 27,
    video: null,
  },
  {
    pageNumber: 28,
    video: {
      url: 'https://www.youtube.com/embed/vXq_peryVxg',
      top: '10%',
      left: '50%',
      transform: 'translateX(-50%)',
    },
  },
  {
    pageNumber: 29,
    video: {
      url: 'https://www.youtube.com/embed/6bncfftJUBU',
      top: '10%',
      left: '50%',
      transform: 'translateX(-50%)',
    },
  },
  {
    pageNumber: 30,
    video: {
      url: 'https://www.youtube.com/embed/14F0kh-zosc',
      top: '10%',
      left: '50%',
      transform: 'translateX(-50%)',
    },
  },
  {
    pageNumber: 31,
    video: {
      url: 'https://www.youtube.com/embed/PF5ZcIuZfQQ',
      top: '10%',
      left: '50%',
      transform: 'translateX(-50%)',
    },
  },
  {
    pageNumber: 32,
    video: null,
  },
  {
    pageNumber: 33,
    video: null,
  },
  {
    pageNumber: 34,
    video: {
      url: 'https://www.youtube.com/embed/F6TO5dZ9iyk',
      top: '10%',
      left: '50%',
      transform: 'translateX(-50%)',
    },
  },
  {
    pageNumber: 35,
    video: {
      url: 'https://www.youtube.com/embed/I3h5QntmpNQ',
      top: '10%',
      left: '50%',
      transform: 'translateX(-50%)',
    },
  },
  {
    pageNumber: 36,
    video: {
      url: 'https://www.youtube.com/embed/ecFh2-s-G-o',
      top: '10%',
      left: '50%',
      transform: 'translateX(-50%)',
    },
  },
  {
    pageNumber: 37,
    video: {
      url: 'https://www.youtube.com/embed/ksWPwu6ldaU',
      top: '10%',
      left: '50%',
      transform: 'translateX(-50%)',
    },
  },
  {
    pageNumber: 38,
    video: null,
  },
  {
    pageNumber: 39,
    video: null,
  },
  {
    pageNumber: 40,
    video: {
      url: 'https://www.youtube.com/embed/8o0O2lBnFCI',
      top: '10%',
      left: '50%',
      transform: 'translateX(-50%)',
    },
  },
  {
    pageNumber: 41,
    video: {
      url: 'https://www.youtube.com/embed/_gfJ43C9Kt0',
      top: '10%',
      left: '50%',
      transform: 'translateX(-50%)',
    },
  },
  {
    pageNumber: 42,
    video: {
      url: 'https://www.youtube.com/embed/b2-v4r6ixU0',
      top: '10%',
      left: '50%',
      transform: 'translateX(-50%)',
    },
  },
  {
    pageNumber: 43,
    video: {
      url: 'https://www.youtube.com/embed/zg48SQKW7ag',
      top: '10%',
      left: '50%',
      transform: 'translateX(-50%)',
    },
  },
  {
    pageNumber: 44,
    video: null,
  },
  {
    pageNumber: 45,
    video: null,
  },
  {
    pageNumber: 46,
    video: {
      url: 'https://www.youtube.com/embed/VzhkUq2LWdM',
      top: '10%',
      left: '50%',
      transform: 'translateX(-50%)',
    },
  },
  {
    pageNumber: 47,
    video: {
      url: 'https://www.youtube.com/embed/tOK-pB697Yk',
      top: '10%',
      left: '50%',
      transform: 'translateX(-50%)',
    },
  },
  {
    pageNumber: 48,
    video: {
      url: 'https://www.youtube.com/embed/0f2ESdHRerA',
      top: '10%',
      left: '50%',
      transform: 'translateX(-50%)',
    },
  },
  {
    pageNumber: 49,
    video: {
      url: 'https://www.youtube.com/embed/9kyXiOTfFNc',
      top: '10%',
      left: '50%',
      transform: 'translateX(-50%)',
    },
  },
  {
    pageNumber: 50,
    video: null,
  },
  {
    pageNumber: 51,
    video: null,
  },
  {
    pageNumber: 52,
    video: {
      url: 'https://www.youtube.com/embed/ISGa9TmBU5o',
      top: '10%',
      left: '50%',
      transform: 'translateX(-50%)',
    },
  },
  {
    pageNumber: 53,
    video: {
      url: 'https://www.youtube.com/embed/NdEx9HQz11I',
      top: '10%',
      left: '50%',
      transform: 'translateX(-50%)',
    },
  },
  {
    pageNumber: 54,
    video: {
      url: 'https://www.youtube.com/embed/KbklhfuVGzU',
      top: '10%',
      left: '50%',
      transform: 'translateX(-50%)',
    },
  },
  {
    pageNumber: 55,
    video: {
      url: 'https://www.youtube.com/embed/A8KAYxjyVqE',
      top: '10%',
      left: '50%',
      transform: 'translateX(-50%)',
    },
  },
  {
    pageNumber: 56,
    video: null,
  },
  {
    pageNumber: 57,
    video: null,
  },
  {
    pageNumber: 58,
    video: {
      url: 'https://www.youtube.com/embed/5notd4J1N54',
      top: '10%',
      left: '50%',
      transform: 'translateX(-50%)',
    },
  },
  {
    pageNumber: 59,
    video: {
      url: 'https://www.youtube.com/embed/4WNIYOcKsqY',
      top: '10%',
      left: '50%',
      transform: 'translateX(-50%)',
    },
  },
  {
    pageNumber: 60,
    video: {
      url: 'https://www.youtube.com/embed/QvnJyBktbGE',
      top: '10%',
      left: '50%',
      transform: 'translateX(-50%)',
    },
  },
  {
    pageNumber: 61,
    video: {
      url: 'https://www.youtube.com/embed/rQMRQavh1u4',
      top: '10%',
      left: '50%',
      transform: 'translateX(-50%)',
    },
  },
  {
    pageNumber: 62,
    video: null,
  },
  {
    pageNumber: 63,
    video: null,
  },
  {
    pageNumber: 64,
    video: {
      url: 'https://www.youtube.com/embed/2OUiaV5Gszo',
      top: '10%',
      left: '50%',
      transform: 'translateX(-50%)',
    },
  },
  {
    pageNumber: 65,
    video: {
      url: 'https://www.youtube.com/embed/dBxXGiGKSfc',
      top: '10%',
      left: '50%',
      transform: 'translateX(-50%)',
    },
  },
  {
    pageNumber: 66,
    video: {
      url: 'https://www.youtube.com/embed/4XivSZEWJ3E',
      top: '10%',
      left: '50%',
      transform: 'translateX(-50%)',
    },
  },
  {
    pageNumber: 67,
    video: {
      url: 'https://www.youtube.com/embed/yMO7Wo1O2hI',
      top: '10%',
      left: '50%',
      transform: 'translateX(-50%)',
    },
  },
  {
    pageNumber: 68,
    video: null,
  },
  {
    pageNumber: 69,
    video: null,
  },
  {
    pageNumber: 70,
    video: null,
  },
];

export default pageData;
