import React, { useState } from 'react';
import { FaYoutube } from 'react-icons/fa';
import { Page as ReactPdfPage } from 'react-pdf';
import Loading from './Loading';

const Page = React.forwardRef(
  ({ width, height, pageNumber, video, setIsVideoOpen, setVideoUrl }, ref) => {
    const [pdfLoading, setPdfLoading] = useState(false);

    return (
      <div ref={ref}>
        {!pdfLoading && (
          <div className='flex justify-center items-center bg-[#282c34] h-full w-full'>
            <Loading />
          </div>
        )}
        {video && (
          <div
            className='absolute z-40'
            style={{
              top: video.top,
              left: video.left,
              transform: video.transform,
            }}
          >
            <FaYoutube className='animate-ping absolute inline-flex w-14 h-14 text-white opacity-75' />
            <FaYoutube
              className='relative inline-flex w-14 h-14 text-[#ff2e17] stroke-white stroke-[15px] cursor-pointer'
              onClick={() => {
                setIsVideoOpen(true);
                setVideoUrl(video.url);
              }}
            />
          </div>
        )}
        <ReactPdfPage
          className={!pdfLoading && 'hidden'}
          onRenderSuccess={setPdfLoading}
          pageNumber={pageNumber}
          width={width}
          height={height}
          renderTextLayer={false}
        />
      </div>
    );
  }
);

export default Page;
