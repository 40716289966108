import React, { useState } from 'react';
import { AiOutlineCloseCircle, AiOutlineShareAlt } from 'react-icons/ai';
import {
  FaEnvelopeSquare,
  FaFacebookSquare,
  FaWhatsappSquare,
} from 'react-icons/fa';
import { FaLinkedin, FaSquareXTwitter } from 'react-icons/fa6';
import { MdDownloadForOffline } from 'react-icons/md';
import {
  EmailShareButton,
  FacebookShareButton,
  LinkedinShareButton,
  TwitterShareButton,
  WhatsappShareButton,
} from 'react-share';

export default function Share({ setShowShare }) {
  // Function to close the Share component
  const closeShare = () => {
    setShowShare(false);
  };

  const bookUrl = 'https://srilankadigitalrecipe.com/';
  const [isCopied, setIsCopied] = useState(false);
  const QRCode = '/Cook-Book-QR.png';

  const copyToClipboard = () => {
    const tempInput = document.createElement('input');
    tempInput.value = bookUrl;
    document.body.appendChild(tempInput);
    tempInput.select();
    document.execCommand('copy');
    document.body.removeChild(tempInput);

    setIsCopied(true);

    setTimeout(() => {
      setIsCopied(false);
    }, 2000);
  };

  //Download QR Code
  const handleQRDownload = () => {
    const imagePath = '/Cook-Book-QR.png'; // Change this to the actual path of your image

    // Create a link element
    const a = document.createElement('a');
    a.href = process.env.PUBLIC_URL + imagePath;
    a.download = 'Cook-Book-QR.png';

    a.click();
  };

  return (
    <div className='fixed top-0 left-0 w-screen h-screen flex items-center justify-center z-[20]'>
      <div
        className='backdrop absolute top-0 left-0 w-screen h-screen bg-black bg-opacity-5 z-[21]'
        onClick={closeShare}
      ></div>
      <div className='share-modal-content absolute bg-white p-4 rounded-md z-[22]'>
        <div className='flex justify-between items-center gap-3'>
          {/* Share header */}
          <div className='flex items-center gap-3'>
            <AiOutlineShareAlt className='text-[26px] opacity-80' />
            <h2 className='font-semibold text-[18px] opacity-80'>
              Share flipbook
            </h2>
          </div>

          {/* Close button */}
          <AiOutlineCloseCircle
            className='cursor-pointer opacity-50 text-[20px]'
            onClick={closeShare}
          />
        </div>

        <div className='flex flex-wrap flex-row mt-4'>
          {/* Social media icons */}
          <div className='flex justify-center md:justify-start gap-3 items-center basis-full md:basis-3/4 order-2 md:order-1 mb-3 md:mb-0'>
            <FacebookShareButton
              url={bookUrl}
              quote='Check out this flipbook!'
              className='cursor-pointer'
            >
              <FaFacebookSquare className='text-[45px]  text-[#4267B2]' />
            </FacebookShareButton>

            <TwitterShareButton
              url={bookUrl}
              quote='Check out this flipbook!'
              className='cursor-pointer'
            >
              <FaSquareXTwitter className='text-[45px]  text-[#1DA1F2]' />
            </TwitterShareButton>

            <LinkedinShareButton
              url={bookUrl}
              title='Check out this flipbook!'
              summary='I found this amazing flipbook and wanted to share it with you!'
              className='cursor-pointer'
            >
              <FaLinkedin className='text-[45px]  text-[#0072b1]' />
            </LinkedinShareButton>

            <EmailShareButton
              url={bookUrl}
              subject='Check out this flipbook!'
              body='I found this amazing flipbook and wanted to share it with you!'
              className='cursor-pointer'
            >
              <FaEnvelopeSquare className='text-[45px] text-[#3e65cf]' />
            </EmailShareButton>

            <WhatsappShareButton
              url={bookUrl}
              quote='Check out this flipbook!'
              className='cursor-pointer'
            >
              <FaWhatsappSquare className='text-[45px] text-[#25D366]' />
            </WhatsappShareButton>
          </div>

          {/* Download button */}
          <div className='flex flex-wrap justify-center md:justify-end relative basis-full md:basis-1/4 order-1 md:order-2 mb-3 md:mb-0'>
            <div>
              <img src={QRCode} alt='' className='w-full qr-img' />
              <MdDownloadForOffline
                className='cursor-pointer text-[20px] text-[#3344a2] float-right'
                onClick={handleQRDownload}
              />
            </div>
          </div>
        </div>

        {/* Copy link section */}
        <div>
          <h3 className='text-[14px] opacity-80'>Copy this link:</h3>
          <div className='flex items-center gap-5 mt-3'>
            <input
              type='text'
              id='disabled-input'
              aria-label='disabled input'
              className='bg-[#3d77ea] bg-opacity-5 border border-gray-300 text-gray-900 text-sm rounded-full block p-2.5 share-link-input'
              value={bookUrl}
              disabled
            />
            <button
              onClick={copyToClipboard}
              className='bg-[#3d77ea] hover:bg-blue-700 text-white font-bold py-2.5 px-6 rounded-full text-[14px] tracking-wide'
              disabled={isCopied}
            >
              {isCopied ? 'COPIED' : 'COPY'}
            </button>
          </div>
        </div>
      </div>
    </div>
  );
}
