import React, { useEffect, useState } from 'react';
import {
  AiOutlineAppstore,
  AiOutlineDownload,
  AiOutlineMenuUnfold,
  AiOutlineShareAlt,
  AiOutlineZoomIn,
} from 'react-icons/ai';
import PageBar from './PageBar';
import Share from './Share';
import Sidebar from './Sidebar';

export default function Header({
  toggleZoomModal,
  flipBook,
  currentPage,
  isZoomOpen,
}) {
  const [isOpen, setIsOpen] = useState(false);
  const [isPageBarOpen, setIsPageBarOpen] = useState(false);
  const [showShare, setShowShare] = useState(false);
  const [isMobileView, setIsMobileView] = useState(false);

  useEffect(() => {
    // Check for mobile view on initial load and when the window is resized
    const handleResize = () => {
      setIsMobileView(window.innerWidth <= 768); // You can adjust the breakpoint as needed
    };

    window.addEventListener('resize', handleResize);
    handleResize(); // Check for mobile view on initial load

    return () => {
      window.removeEventListener('resize', handleResize);
    };
  }, []);

  // Function to toggle the Sidebar and close the Share component
  const toggleSidebar = () => {
    setIsOpen(!isOpen);
    setShowShare(false);
    setIsPageBarOpen(false);

    if (isZoomOpen) {
      toggleZoomModal();
    }
  };

  const togglePageBar = () => {
    setIsPageBarOpen(!isPageBarOpen);
    setShowShare(false);
    setIsOpen(false);

    if (isZoomOpen) {
      toggleZoomModal();
    }
  };

  const closeSidebar = () => {
    setIsOpen(false);
  };

  const closePageBar = () => {
    setIsPageBarOpen(false);
  };

  // Function to toggle the Share component and close the Sidebar
  const toggleShare = () => {
    setShowShare(!showShare);
    setIsOpen(false);
    setIsPageBarOpen(false);
  };

  // Function to trigger PDF download
  // const downloadPdf = () => {
  //   // Create a link element
  //   const link = document.createElement('a');
  //   link.href = '/cook-book.pdf'; // Relative path to the PDF file in the public directory
  //   link.download = 'cook-book.pdf'; // Set the desired file name
  //   link.click();
  // };

  const handleDownload = () => {
    // Use the currentPage prop to determine which page to download
    const pageNumber = currentPage;

    // Get the device's current orientation
    const orientation = window.orientation;

    // Convert the orientation value to 'portrait' or 'landscape'
    const orientationString = orientation === 0 ? 'P' : 'L';

    const imageUrl = `/book-pages/${orientationString}${pageNumber}.jpg`;

    // Trigger the download by creating an invisible link and simulating a click
    const downloadLink = document.createElement('a');
    downloadLink.href = imageUrl;
    downloadLink.download = `page-${pageNumber}.jpg`;
    downloadLink.style.display = 'none';
    document.body.appendChild(downloadLink);
    downloadLink.click();
    document.body.removeChild(downloadLink);
  };

  return (
    <div>
      <div className='flex items-center justify-center absolute left-[0] w-[100%] mt-[-70] py-[7px] z-[4] bg-black bg-opacity-40'>
        <ul className='flex gap-8 items-center '>
          {/* Sidebar toggle */}
          <li
            className='text-white items-center w-8 h-8 hover:scale-110 cursor-pointer'
            onClick={toggleSidebar}
          >
            <AiOutlineMenuUnfold className='text-[26px]' />
          </li>
          {/* PageBar toggle */}
          <li
            className='text-white items-center w-8 h-8 hover:scale-110 cursor-pointer'
            onClick={togglePageBar}
          >
            <AiOutlineAppstore className='text-[26px]' />
          </li>
          {/* Share component toggle */}
          <li
            className='text-white items-center w-8 h-8 hover:scale-110 cursor-pointer'
            onClick={toggleShare}
          >
            <AiOutlineShareAlt className='text-[26px]' />
          </li>
          {/* PDF download trigger */}
          <li
            className='text-white items-center w-8 h-8 hover:scale-110 cursor-pointer'
            // onClick={downloadPdf}
            onClick={handleDownload}
          >
            <AiOutlineDownload className='text-[26px]' />
          </li>
          <li
            className='text-white items-center w-8 h-8 hover:scale-110 cursor-pointer'
            onClick={toggleZoomModal}
          >
            <AiOutlineZoomIn className='text-[26px]' />
          </li>
        </ul>
      </div>

      <div>
        <Sidebar
          isOpen={isOpen}
          flipBook={flipBook}
          closeSidebar={isMobileView ? closeSidebar : () => {}}
        />

        {showShare && (
          <div className='fixed top-0 left-0 w-screen h-screen bg-black bg-opacity-70 flex items-center justify-center z-20'>
            <Share setShowShare={setShowShare} />
          </div>
        )}

        <PageBar
          isPageBarOpen={isPageBarOpen}
          flipBook={flipBook}
          closePageBar={isMobileView ? closePageBar : () => {}}
        />
      </div>
    </div>
  );
}
