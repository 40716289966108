import React from 'react';
import content from '../data/content';

export default function Sidebar({ isOpen, flipBook, closeSidebar }) {
  const sidebarStyle = {
    transform: isOpen ? 'translateX(0)' : 'translateX(-249px)', // Use conditional transform property
    WebkitBackfaceVisibility: 'hidden', // Add this property to improve rendering quality
  };

  return (
    <div className='sidebar' style={sidebarStyle}>
      {isOpen && (
        <div className='bg-black bg-opacity-80 w-full h-full pb-[20px]'>
          <h2 className='text-white text-[12px] font-thin tracking-wider px-4 py-2'>
            TABLE OF CONTENT
          </h2>
          <div className='mt-[10px] w-full gap-3 sidebar-menu'>
            {content.map((item, index) => (
              <div
                className='flex justify-between hover:bg-white hover:bg-opacity-20 py-2 px-4 cursor-pointer'
                onClick={() => {
                  flipBook.current.pageFlip().flip(item.Page);
                  closeSidebar();
                }}
              >
                <p className='text-white text-[14px] font-semibold '>
                  {item.name}
                </p>
                <p className='text-white text-[14px] font-medium'>
                  {item.Page}
                </p>
              </div>
            ))}
          </div>
        </div>
      )}
    </div>
  );
}
