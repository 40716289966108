import React from 'react';
import content from '../data/pageBarContent';

export default function PageBar({ isPageBarOpen, flipBook, closePageBar }) {
  const pageBarStyle = {
    transform: isPageBarOpen ? 'translateX(0)' : 'translateX(-249px)', // Use conditional transform property
    WebkitBackfaceVisibility: 'hidden', // Add this property to improve rendering quality
  };

  return (
    <div className='pagebar sidebar' style={pageBarStyle}>
      {isPageBarOpen && (
        <div className='bg-black bg-opacity-80 w-full h-full pb-[56px]'>
          <div className='mt-[10px] mb-[50px] flex flex-wrap justify-center w-full pagebar-menu py-2 px-4 pagebar-main-content'>
            {content.map((item, index) => (
              <div
                className='flex justify-between cursor-pointer pagebar-content my-3'
                onClick={() => {
                  flipBook.current.pageFlip().flip(item.Page);
                  closePageBar();
                }}
              >
                <div className='text-center'>
                  <img src={item.img} className='w-full' alt='' />
                  <p className='text-white text-[14px] font-medium'>
                    {item.Page + 1}
                  </p>
                </div>
              </div>
            ))}
          </div>
        </div>
      )}
    </div>
  );
}
